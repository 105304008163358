<template>
  <div class="registration-layout" ref="layoutRef">
    <div
      v-if="
        entryType === 'registration' &&
        (registrationStore.focusItems.length !== 0 ||
          registrationStore.jobQuery !== '')
      "
      class="introduce-header"
      ref="introduceHeaderRef"
    >
      <div
        v-if="registrationStore.jobQuery !== ''"
        class="introduce-header_inner"
      >
        <p class="introduce-header_title">ご覧になった求人</p>
        <p class="introduce-header_item">{{ jobQuery }}</p>
      </div>
      <div
        v-if="registrationStore.focusItems.length !== 0"
        class="introduce-header_inner"
      >
        <p class="introduce-header_title">重視する項目</p>
        <p class="introduce-header_item">{{ introduceItem }}</p>
      </div>
    </div>
    <div class="step-header">
      <span v-if="entryType !== 'registration'" class="step-header_head-title">
        {{ header.headTitle }}
      </span>
      <span v-else class="step-header_head-title emphasis">
        無料支援転職サービスにご登録
      </span>
      <div v-if="entryType !== 'registration'" class="steps">
        <span
          v-for="index in step"
          :key="index"
          class="steps_mark"
          :class="{
            'steps_mark-current': currentStep === index,
            'steps_mark-passing': currentStep >= index,
          }"
        />
      </div>
      <span class="step-header_sub-title">{{ header.subTitle }}</span>
    </div>
    <div class="layout-contents">
      <slot />
    </div>
    <div class="layout-fixed">
      <div class="layout-fixed_buttons">
        <RegisterFormFormBackButton
          v-if="currentStep !== 1"
          :on-click="onClickBackButton"
        />
        <RegisterFormFormDefaultButton
          v-if="nextButton.isNext"
          :is-disabled="nextButton.isDisabled"
          color-type="blue"
          label="次へ"
          :on-click="onClickNextButton"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FOCUS_ITEMS } from '~/const/regist/focusItems';
import { useRegisterLayout } from '~/hooks/useRegisterLayout';
import { useRegisterFormStore } from '~/store/registerForm';
import { useRegistrationStore } from '~/store/registration';
import { useUtmParameter } from '~/hooks/useUtmParameter';

useUtmParameter();
const { headers, nextButton, onClickBackButton, onClickNextButton } =
  useRegisterLayout();

const registerFormStore = useRegisterFormStore();
const registrationStore = useRegistrationStore();

const introduceHeaderRef = ref<HTMLDivElement | null>(null);
const layoutRef = ref<HTMLDivElement | null>(null);

const header = computed(() => {
  return headers.value[registerFormStore.currentStep - 1];
});

const entryType = computed(() => {
  return registerFormStore.entryType;
});

const currentStep = computed(() => {
  return registerFormStore.currentStep;
});

const step = computed(() => {
  return headers.value.length;
});

const jobQuery = computed(() => {
  return registrationStore.jobQuery;
});

const introduceItem = computed(() => {
  return registrationStore.focusItems.join(' / ');
});

watch(
  () => registerFormStore.currentStep,
  (newVal) => {
    if (
      entryType.value === 'registration' &&
      layoutRef.value &&
      introduceHeaderRef.value &&
      newVal !== 1
    ) {
      layoutRef.value.style.minHeight =
        window.innerHeight + introduceHeaderRef.value.offsetHeight + 'px';
      window.scrollTo({
        top: introduceHeaderRef.value.offsetHeight,
        behavior: 'smooth',
      });
    }
  },
);
</script>
